<template>
  <!-- 移动端首页 -->
  <div class="demoIndex">
    <div class="header">
      <i class="el-icon-alifanhui" @click="back"></i>
      <div>{{demoName}}</div>
      <div></div>
    </div>
    <div class="mainImg">
      <img src="../../assets/img/demoIndexImg.png" alt="">
    </div>
    <ul class="projectList">
      <li class="videoStudy" @click="linkUrl('videoStudy')">
        <p>视频学习</p>
      </li>
      <!-- <li class="testStudy">
        <p>通过性自我测验</p>
      </li>
      <li class="guideStudy" @click="goHtml">
        <p>实训引导</p>
      </li> -->
      <li class="evaluateStudy" @click="linkUrl('evaluateStudy')">
        <p>操作评价</p>
      </li>
      <li class="evaluateStudy" @click="linkUrl('evaluateRecord')">
        <p>评价记录</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      demoIndex: 0,
      demoName: "",
    }
  },
  methods: {
    // 点击头部返回按钮
    back() {
      this.$router.push({ name: "demoStudy" });
    },
    linkUrl(url) {
      this.$router.push({ name: url });
      this.cookies.kkSet("demoIndex", this.demoIndex);
      this.cookies.kkSet("useTime", 0);
    },
    goHtml() {
      location.href = "http://sxt.osce.cc/WxLogin/UserBind.aspx?reurl=http%3a%2f%2fsxt.osce.cc/%2fWebApp%2fMemory%2fMemoryIndex.aspx";
    }
  },
  mounted() {
    this.demoName = this.cookies.kkGet("demoName");
  }
}
</script>

<style>
.demoIndex .header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #101010;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #f8f8f8;
}
.demoIndex .header i {
  margin-left: 18px;
  font-size: 20px;
  display: block;
  width: 30px;
}
.demoIndex .header div:last-child {
  width: 30px;
}
.demoIndex .mainImg {
  width: 100%;
  height: 216px;
  margin-top: 44px;
  padding: 15px;
}
.demoIndex .mainImg img {
  width: 100%;
  height: 100%;
}
.demoIndex .projectList {
  padding: 0 34px;
  width: 100%;
}
.demoIndex .projectList li {
  width: 100%;
  height: 80px;
  margin: 20px 0;
  border-radius: 4px;
  border: 1px solid rgba(187, 187, 187, 100);
  border-left: none;
  border-top: none;
  box-shadow: -10px 0px 0px #eeeded;
  padding: 10px 8px;
}
.demoIndex .projectList li p {
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0 5px;
}
.demoIndex .projectList li span {
  color: #aaa;
}
</style>